import React from 'react'
import SellerLayoutAdjustment from '../components/SellerLayoutAdjustment'
import SellerSubscriptionSelection from '../components/SellerSubscriptionSelection'
import { useState } from 'react';
import { useContext } from 'react';
import { SellerContext } from '../context/SellerContext';
import { useEffect } from 'react';
import { API_BASE_URL } from '../App';
import { confirmAlert, errorAlert, okAlert, successAlert } from '../components/Alert';
import { CircularProgress } from '@mui/material';
import Loading from '../components/Loading';

const SellerSubsPlanPage = () => {
  const { sellerData, logout, accessToken, handleAuthExpiry, getSellerPlan } = useContext(SellerContext)
  const [selectedPlan, setSelectedPlan] = useState();
  const [subsPlan, setSubsPlan] = useState("");
  const [annualPlanPayment, setAnnualPlanPayment] = useState(true)
  const [aadharLoading, setAadharLoading] = useState(false);
  const [sellerPlanDetails, setSellerPlanDetails] = useState();
  const [sellerPlanName, setSellerPlanName] = useState();
  const [sellerSubscriptionData, setSellerSubscriptionData] = useState();
  const [currentPlanDetails, setCuurentPlanDetails] = useState();
  const [sellerAddressData, setSellerAddressData] = useState();
  const [planDetailArr, setPlanDetailArr] = useState();
  const[loading,setLoading]=useState(false);
  const [planUpdated, setPlanUpdated] = useState(0)

  const fetchSellerAddressData = async () => {
    setLoading(true);
    if (!sellerData?.result?.userID) {
      setLoading(false);
      return;
    }
    try {
      // setLoading(true);
      const response = await fetch(

        `${API_BASE_URL}/address/api/v1/getAll?userId=${sellerData?.result?.userID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        setLoading(false);
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      setSellerAddressData(data);
      setLoading(false);
      ////console.log("Seller Address Data:", data?.result);
    } catch {
      setLoading(false);

    }
  }

  const fetchSubscriptionData = async () => {
    // setLoading(true);
    const response = await fetch(`${API_BASE_URL}/subscription/api/v1/getall`, {
      // mode: "no-cors",
      headers: {
        // 'Accept': 'text/plain',
        // 'Content-Type': 'text/plain',
        Authorization: `Bearer ${accessToken}`
      },
    });
    const data = await response.json();
    setSellerSubscriptionData(data);
    ////console.log(data);
    setLoading(false);
  };


  const getSellerCurrentPlan = async () => {
    try {
      // setLoading(true);
      if (!sellerData?.result?.userID) {
        setLoading(false);
        return;

      }
      // const plan = await fetch(`${API_BASE_URL}/subscription/api/v1/mapping/get/${sellerData?.result?.userID}/false/true`, {
      const plan = await fetch(`${API_BASE_URL}/subscription/api/v1/mapping/get?sellerId=${sellerData?.result?.typeOfUser === 'SLADM' ? sellerData?.result?.userID : sellerData?.result?.userAdminId}&onlyActivePlan=true&checkValidation=true`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }

      })
      const response = await plan.json();
      //console.log(response)
      const planDetails = response?.result;
      setLoading(false);
      setSellerPlanDetails(response?.result)
      decipherPlan(response?.result)
      return planDetails;
    } catch (e) {
      setLoading(false);
      ////console.log("COuldnt fetch plan")
      //console.log('CArch')
    }

  }
  const getSellerPlanName = async () => {
    try {
      // setLoading(true);
      if (!sellerData?.result?.userID) {
        setLoading(false);
        return;
      }
      // const plan = await fetch(`${API_BASE_URL}/subscription/api/v1/mapping/get/${sellerData?.result?.userID}/false/true`, {
      const plan = await fetch(`${API_BASE_URL}/subscription/api/v1/mapping/get?sellerId=${sellerData?.result?.typeOfUser === 'SLADM' ? sellerData?.result?.userID : sellerData?.result?.userAdminId}&onlyActivePlan=false&checkValidation=false`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }

      })
      const response = await plan.json();
      // console.log(response)
      const planDetails = response?.result;
      setLoading(false);
      setSellerPlanName(response?.result[response?.result?.length - 1]?.Title)
      // console.log(response?.result[response?.result?.length-1]?.Title)
      return planDetails;
    } catch (e) {
      setLoading(false);
      ////console.log("COuldnt fetch plan")
      //console.log('CArch')
    }

  }

  useEffect(() => {
    // console.log(sellerSubscriptionData?.result)
    // console.log(sellerPlanName)
    setSelectedPlan(sellerSubscriptionData?.result?.filter(item => item.title === sellerPlanName)?.[0])
    // console.log(sellerSubscriptionData?.result?.filter(item => item.title === sellerPlanName)?.[0])
  }, [sellerSubscriptionData])

  useEffect(() => {
    fetchSubscriptionData();
    getSellerCurrentPlan();
    getSellerPlanName();
    fetchSellerAddressData();
  }, [sellerData, planUpdated])

  useEffect(()=>{
    getSellerPlan()
  },[planUpdated])


  useEffect(() => {
    // console.log(sellerSubscriptionData?.result?.filter(item => item.title === sellerPlanName)?.[0])
    setCuurentPlanDetails(sellerSubscriptionData?.result?.filter(item => item.title === sellerPlanName)?.[0])
  })


  const selectPlan = async () => {
    setAadharLoading(true);
    const result= await isSubscriptionAllowed()
    if(result!==true){
      // console.log(result);
      setLoading(false);
      setAadharLoading(false);
      return;
    }
    if (subsPlan === "Basic") {
      await mapBasicPlan();
      setLoading(false);
      setAadharLoading(false)
    } else {

      ////console.log(selectedPlanId);
      setLoading(false);
      purchaseSubscription(selectedPlan);
    }

  };
  function decipherPlan(sellerPlanDetails) {
    const elements = sellerPlanDetails?.toString().split(".");
    setPlanDetailArr(elements);
    // console.log(elements);
  }

  const isSubscriptionAllowed = async () => {
    try {
      // setLoading(true);
      if(!planDetailArr[2]){
        errorAlert('','Something went wrong.');
        setLoading(false);
        return false;
      }
      if (selectedPlan?.features[9]?.descriptionValue === "Unlimited") {
        setLoading(false);
        return true;
      }
      const currentPlanAllowance = sellerSubscriptionData?.result?.filter(item => item.title === sellerPlanName)?.[0];
      if (
        
        (parseInt(selectedPlan?.features[1]?.descriptionValue) < (parseInt(currentPlanDetails?.features[1]?.descriptionValue) - parseInt(planDetailArr[1])))
         || (parseInt(selectedPlan?.features[9]?.descriptionValue) < (parseInt(currentPlanDetails?.features[9]?.descriptionValue) - parseInt(planDetailArr[9])))
         || (parseInt(selectedPlan?.features[8]?.descriptionValue) < (parseInt(currentPlanDetails?.features[8]?.descriptionValue) - parseInt(planDetailArr[8])))

      ) {
        okAlert('', `
        <small>
    To downgrade to <strong>${selectedPlan?.title?.toUpperCase()}</strong> plan, 
    adjustments to your current plan are required:
</small><br></br>

${(parseInt(selectedPlan?.features[1]?.descriptionValue) < (parseInt(currentPlanDetails?.features[1]?.descriptionValue) -  parseInt(planDetailArr[1]))) ? 
    `<small>Maximum allowed products: <strong>${parseInt(selectedPlan?.features[1]?.descriptionValue)}</strong>. 
    You have <strong>${parseInt(currentPlanDetails?.features[1]?.descriptionValue) - parseInt(planDetailArr[1])}</strong> product/s.</small>` : ''}
    <br>
${(parseInt(selectedPlan?.features[9]?.descriptionValue) < (parseInt(currentPlanDetails?.features[9]?.descriptionValue) - parseInt(planDetailArr[9]))) ? 
    `<small>Maximum allowed Users:<strong> ${parseInt(selectedPlan?.features[9]?.descriptionValue)}. </strong>
    You have <strong>${parseInt(currentPlanDetails?.features[9]?.descriptionValue) -  parseInt(planDetailArr[9])}</strong> User/s.</small>` : ''}
    <br>
    ${(parseInt(selectedPlan?.features[8]?.descriptionValue) < (parseInt(currentPlanDetails?.features[8]?.descriptionValue) - parseInt(planDetailArr[8]))) ? 
      `<small>Maximum products with videos allowed: <strong>${parseInt(selectedPlan?.features[8]?.descriptionValue)}.</strong> 
    You have <strong>${parseInt(currentPlanDetails?.features[8]?.descriptionValue) -  parseInt(planDetailArr[8])}</strong> product/s with Videos.</small>` : ''}
    <br>
<small>If you wish to retain these features, complete your payment to stay on your existing plan. 
    To proceed with the downgrade, remove these features manually</small>
    `, () => { return }); 
      }else{
        setLoading(false);
        return true;
      }

    } catch {
      setLoading(false);
    }
  }

  useEffect(() => {
  // console.log( 'Max Allowed',selectedPlan?.features[9]?.descriptionValue)
  // console.log('currently available limit',  planDetailArr[9]    )
  // console.log('Maximum available in current Plan',  currentPlanDetails?.features[9]?.descriptionValue)
  // console.log('Currently Added Products',  parseInt(currentPlanDetails?.features[9]?.descriptionValue) - parseInt(planDetailArr[9]))
  }, [selectedPlan])


  const purchaseSubscription = async (selectedPlan) => {
    setAadharLoading(true)
    setLoading(true);

    setLoading(true);
    // okAlert(``,'<h2>Apologies for the inconvenience.</h2><p>Currently, only the <strong>Basic</strong> plan is active. Please select the <strong>Basic</strong> plan for now. The other subscriptions will be available shortly.</p> <p><strong>Thank you for your patience.</strong></p>',()=>{return})
    // return;
    // ////console.log(cartData.cartId)
    const requestData = {
      userId: sellerData?.result?.userID,
      Name: sellerData?.result?.fullName,
      Email: sellerData?.result?.email,
      PhoneNumber: sellerData?.result?.mobileNumber,
      DeliveryAddress: "string",
      DeliveryPlace: "string",
      DeliveryState: "string",
      DeliveryPincode: "string",
      BillingAddress: sellerAddressData?.result[0]?.addressLine1 + '' + sellerAddressData?.result[0]?.addressLine2,
      BillingPlace: sellerAddressData?.result[0]?.city,
      BillingState: sellerAddressData?.result[0]?.state,
      BillingPincode: sellerAddressData?.result[0]?.pincode,
      PaymentMode: "Online",
      Amount: annualPlanPayment ? (selectedPlan.annualValue + (selectedPlan.annualValue * 0.18)) : (selectedPlan.value + (selectedPlan.value * 0.18)),
      cartId: 0,
      planID: selectedPlan.id,
      shipcost: 0,
      couponID: 'string',
      planMonthsCount: annualPlanPayment ? 12 : 1
    };


    if(!isSubscriptionAllowed()){
      setLoading(false);
      return;

    }


    const response = await fetch(`${API_BASE_URL}/payments/api/v1/PhonepeRequestPayment`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });
    // ////console.log("Anand", cartData)
    ////console.log("SUMAN", requestData)

    const responseData = await response.json();
    setLoading(false);
    const handleAuthError = handleAuthExpiry(response);
    if (handleAuthError === true) {
      setLoading(false);
      errorAlert('', 'Your Session has expired');
      logout();
      setAadharLoading(false)

      return;
    }
    ////console.log(responseData);
    if (responseData && responseData.result) {
      try {
        const resultData = JSON.parse(responseData.result);

        // PHONE PE
        if (resultData.data && resultData.data.instrumentResponse && resultData.data.instrumentResponse.redirectInfo) {
          //STRIPE PAYMENT if (resultData && resultData?.url) {
          const redirectURL = resultData.data.instrumentResponse.redirectInfo.url;
          ////console.log(redirectURL);
          // Redirect the user to the provided URL
          window.location.href = redirectURL;
          setAadharLoading(false)
          setLoading(false);
        } else {
          console.error('Invalid response format or missing redirect URL.');
          // Handle the case where the response does not contain a valid redirect URL.
          setAadharLoading(false)
          setLoading(false);

        }
      } catch (error) {
        console.error('Error parsing JSON from result field:', error);
        // Handle any JSON parsing errors that may occur.
        setLoading(false);
        setAadharLoading(false)

      }
    } else {
      // console.error('Invalid response format or missing result field.');
      setLoading(false);
      // Handle the case where the response does not contain a valid result field.
      setAadharLoading(false)

    }


  }

  const checkPlan = async () => {

    const response = await fetch(`${API_BASE_URL}/subscription/api/v1/mapping/get/sellerId=${sellerData?.result?.userID}&onlyActivePlan=true&checkValidation=false`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json-patch+json',
      },


    })
    const data = await response.json();
    setLoading(false);
    const handleAuthError = handleAuthExpiry(response);
    if (handleAuthError === true) {
      setLoading(false);
      errorAlert('', 'Your Session has expired');
      logout();
      return;
    }
    ////console.log(data);
    if (data?.result?.[0]?.Title) {
      setLoading(false);
      return true

    }
    else {
      setLoading(false);
      return false
    }
  }



  const mapBasicPlan = async () => {
    const check = await checkPlan();
    if (check) {
      setLoading(false);
    }

    else {

      const response = await fetch(`${API_BASE_URL}/subscription/api/v1/mapping/create`, {

        method: 'POST',

        headers: {

          Authorization: `Bearer ${accessToken}`,

          'Content-Type': 'application/json-patch+json',

        },

        body: JSON.stringify({

          "sellerId": sellerData?.result?.userID,

          "sellerEmailid": sellerData?.result?.email,

          "subscriptionId": 1,

          "isBillingAnnual": true,

          "paymentStatus": true,

          "transactionId": "Default"

        })



      })

      const result = await response.json();
      setLoading(false);
      if (result?.result === true) {
        fetchSubscriptionData();
        setLoading(false);
        setPlanUpdated(planUpdated++)
        successAlert('', 'Basic Plan has been Activated!')
      } else {
        setLoading(false);
        errorAlert('', 'Could not activate plan. Please try again.')

      }
      ////console.log(result);
    }

  }


  return (


    <SellerLayoutAdjustment>
        {!(!loading && sellerAddressData && sellerPlanName && sellerPlanDetails && sellerSubscriptionData )&&<div className="loading-container" style={{ minHeight: "300px", minWidth: "80%" ,width:"1000px !important"}}>
            <Loading />
          </div>}
          {!loading && sellerAddressData && sellerPlanName && sellerPlanDetails && sellerSubscriptionData &&<div>
      <div className="submit-models-container">
    
        <div className="subs-models-header">
          Subscription Plans
        </div>
        <div className="subs-list-note  ">
          Make the wise decision for your business
        </div>
      
        <SellerSubscriptionSelection
          subsPlan={subsPlan}
          setSubsPlan={setSubsPlan}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          setSellerSubscriptionData={setSellerSubscriptionData}
          sellerSubscriptionData={sellerSubscriptionData}
          planName={sellerPlanName}
        />
        {selectedPlan?.title === sellerPlanName &&  <div className="seller-subscription-checkbox" style={{marginBottom:'2rem'}}>You already Have this Subscription</div>}
        {/* {loading && <div className="loading-container" style={{ minHeight: "300px", minWidth: "80%" , width:"920px"}}>
            <Loading />

          </div>} */}
        {selectedPlan?.title !== sellerPlanName && <div style={{maxWidth:'90%', margin:'0 auto'}}>
          {selectedPlan && <div className="seller-subscription-checkbox">
            {selectedPlan.annualDiscount > 0 && <input type='checkbox' style={{ width: '24px', transform: 'scale(1.8)' }} checked={annualPlanPayment} onChange={() => { setAnnualPlanPayment(!annualPlanPayment) }} />}{selectedPlan.annualDiscount > 0 && <label>Choose to pay annually</label>}
            {selectedPlan.annualDiscount > 0 && <label > {''} and get {selectedPlan.annualDiscount} % discount
              ({annualPlanPayment ? selectedPlan.annualValue : selectedPlan?.value} + GST)

            </label>}

          </div>}

          <div>
            {!aadharLoading && selectedPlan?.annualValue === 0 && <button
              type="button"
              className="common-btn"
              onClick={selectPlan}
              style={{ marginBottom: '60px', float: 'right' }}
            >
              Continue with Free Plan
            </button>}
            {!aadharLoading && selectedPlan?.annualValue > 0 && <button
              type="button"
              className="common-btn"
              onClick={selectPlan}
              style={{ marginBottom: '60px', float: 'right' }}
            >
              Pay ₹ {annualPlanPayment ? selectedPlan.annualValue : selectedPlan?.value} +GST/ {annualPlanPayment ? 'Year' : 'Month'}
            </button>}
            {aadharLoading && <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "flex-end" }}><CircularProgress style={{ color: "pink" }} /></div>}
          </div>
        </div>}
        </div>



      </div>}
    </SellerLayoutAdjustment>
  )
}

export default SellerSubsPlanPage